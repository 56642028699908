<template>
  <div class="footer">
    <div class="container">
      <div class="footer-content">
        <div class="footer_links">
          <div>
            <div class="logo">
              <img src="/img/logo.svg" alt="logo" />
            </div>
            <p>
              Enjoy seamless giftcard trading. Start trading conviniently and
              get cash.
            </p>
            <div class="contact_links">
              <ul>
                <li><router-link to="/faq">FAQ</router-link></li>
                <li>
                  <router-link to="/policy">Privacy Policy</router-link>
                </li>
                <li>
                  <router-link to="/terms-of-use">Terms of Use</router-link>
                </li>
                <li>
                  <router-link to="/aml-policy">AML Policy</router-link>
                </li>
                <li>
                  <router-link to="/delete-account">Account</router-link>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="mailto:support@easyflip.in">support@easyflip.in</a>
                </li>
                <li><a href="tel:+2348150512114">+2348150512114</a></li>
              </ul>
            </div>
          </div>
          <div class="app">
            <h1>Get the app</h1>
            <div class="app-img">
              <a
                href="https://apps.apple.com/ng/app/easyflip-flip-cards/id1605670456"
              >
                <img src="/img/app-store-01.svg" alt="app store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.easyflipMobile&hl=en&gl=US"
              >
                <img src="/img/google-play-01.svg" alt="google play" />
              </a>
            </div>
          </div>
        </div>
        <ul class="social_links">
          <li>
            <a
              href="https://wa.me/+2348150512114?text="
              target="_blank"
              rel="noopener noreferrer"
              ><img src="/img/whatsapp.svg" alt="whatsapp"
            /></a>
            <a
              href="https://www.facebook.com/EasyflipN?mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
              ><img src="/img/facebook.svg" alt="facebook"
            /></a>
            <a
              href="http://www.instagram.com/easyflipng"
              target="_blank"
              rel="noopener noreferrer"
              ><img src="/img/instagram.svg" alt="instagram"
            /></a>
          </li>
          <small>&copy; 2023 Easyflip. All Rights Reserved.</small>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterTag",
};
</script>
<style lang="scss">
@import "@/scss/global.scss";
.footer {
  background: $sec-color;
  color: $white;
  padding: 2rem 0;
  &-content {
    display: grid;
    & a {
      color: $white;
    }
    & img {
      width: 100px;
    }
    & p {
      font-size: 14px;
      color: #eeeeee;
      padding: 1.5rem 0;
    }
  }
}

.contact_links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & ul li {
    list-style: none;
    text-align: left;
    padding: 0 0 1rem;
  }
  & ul li:first-child {
    font-weight: normal;
    padding-bottom: 1rem;
    font-size: 15px;
  }
  & ul li img {
    width: 18px;
    height: 18px;
    margin-right: 1rem;
  }
  & ul:nth-child(2) li:last-child {
    display: flex;
    align-items: center;
  }
}

.app {
  padding: 1.5rem 0;
  & h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $white;
    padding-bottom: 1rem;
  }
  &-img {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;

    & img {
      width: 135px;
      height: 40px;
    }
  }
}

.social_links {
  border-top: 1px solid #525295;
  padding: 1.5rem 0;
  & li {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
  }
  & img {
    width: 24px;
    height: 24px;
  }
  & small {
    color: #d6bbfb;
  }
}

@media screen and (min-width: 700px) {
  .footer {
    &-content {
      display: grid;
      gap: 1.5rem;
      & a {
        color: $white;
      }
      & p {
        width: 320px;
      }
    }
  }

  .social_links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    & li {
      padding-bottom: 0rem;
      order: 2;
    }
    & small {
      order: 1;
    }
  }
}
@media screen and (min-width: 1000px) {
  .footer_links {
    display: flex;
    justify-content: space-between;
  }
  .contact_links {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    gap: 1rem;
    & ul {
      display: flex;
      gap: 1rem;
    }
    & ul li {
      padding: 0rem;
    }
    & ul li:first-child {
      padding-bottom: 0rem;
    }
  }

  .app {
    & h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $white;
      padding-bottom: 1rem;
    }
    &-img {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      & img {
        width: 135px;
        height: 40px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .footer {
    &-content {
      width: 1200px;
      margin: auto;
    }
  }
}
</style>
